import React, { Component } from "react";
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = process.env.NODE_ENV === 'development' ? loadStripe('pk_test_CyjB5LHU8B7GFVUVznx9oIX7') : loadStripe('pk_live_UEb3jCRneS64TPIcqTOrA1dQ');

class CheckoutScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false
    };
  }
  componentDidMount() {
    this.redirectNow()
  }

  redirectNow = async() => {
    const { match: { params } } = this.props;
    const stripe = await stripePromise;
    try{
      await stripe.redirectToCheckout({
        sessionId: params.id,
      });
    }catch(error){
      this.setState({ error: true })
      // console.log(error)
    }
  }


  render() {
    return (
      <div style={{
        flex: 1, textAlign: 'center',
        justifyContent: 'center', fontSize: 12,
        fontFamily: 'sans-serif',
        padding: 30
      }}>
        { !this.state.error ? "Please Wait" : "Error: Invalid checkout session." }
      </div>
    );
  }
}

export default CheckoutScreen;
