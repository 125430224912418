import React, { PureComponent } from "react";

class SuccessScreen extends PureComponent {

  render() {
    return (
      <div style={{
        flex: 1,
        textAlign: 'center',
        justifyContent: 'center',
        fontSize: 12,
        fontFamily: 'sans-serif',
        padding: 30
      }}>
        <h1>{ "Payment Success" }</h1>
        <p>{"Thank you for your payment. You may now close this window."}</p>
      </div>
    );
  }
}

export default SuccessScreen;
