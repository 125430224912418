import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CheckoutScreen from './CheckoutScreen'
import SuccessScreen from './SuccessScreen'

function App() {
  return (
    <Router>
        <div className="App">
          <Switch>
              <Route
                exact path="/:locale?/cancel" component={ () => (<div/>)} />
              />
              <Route
                exact path="/:locale?/success" component={SuccessScreen} />
              />
              <Route
                exact path="/session/:id?" component={CheckoutScreen}
              />
              <Route component={ () => (<div/>)} />
          </Switch>
        </div>
    </Router>
  );
}

export default App;
